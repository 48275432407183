import { Component, OnInit } from '@angular/core';
import Access from './models/access.model';
import { WasheriaService } from './washeria.service';

@Component({
  selector: 'washeria-accesses',
  templateUrl: './washeria-accesses.component.html',
  styleUrls: ['./washeria-accesses.component.scss']
})
export class AccessesComponent implements OnInit {

  data:Array<Access> = [];

  constructor(private ws: WasheriaService) { }

  ngOnInit() {
    this.ws.getAccesses()
      .subscribe(data => {
        this.data = data['data'];
      });
  }
}
