import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessesComponent } from './washeria-accesses.component';
import { QRCodesComponent } from './washeria-qrcodes.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'accesses',
    pathMatch: 'full'
  },
  {
    path: 'accesses',
    component: AccessesComponent,
  },
  {
    path: 'qrcodes',
    component: QRCodesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WasheriaRoutingModule { }
