import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_URL } from './../../config';
import Access from './models/access.model';


@Injectable()
export class WasheriaService {
  
  constructor(private http: HttpClient) {}

  getAccesses():Observable<Array<Access>> {
    return this.http.get<Array<Access>>(`${API_URL}/accesses`);
  }

  sendDoorOpenRequest():Observable<any> {
    return this.http.post(`${API_URL}/open`, {});
  }

  getLastQR():Observable<HttpResponse<any>> {
    return this.http.get<any>(`${API_URL}/qr`, { observe: 'response' });
  }
}