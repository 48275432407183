import { CommonModule } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { UserService } from './shared/services/user.service';
import { WasheriaModule } from './layout/washeria/washeria.module';
import { tokenGetterFn } from './layout/token-getter';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { JwtInterceptor } from '../app/layout/jwt.interceptor';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
        '.json'
    ); */
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        
        JwtModule.forRoot({
          config: {
            tokenGetter: tokenGetterFn,
            whitelistedDomains: ['localhost:8000/accesses','localhost', 'localhost:8100', 'localhost:8000', 'api.washeria.ro'],
            authScheme: 'Bearer ',
            blacklistedRoutes: ['localhost:3001/auth/']
          }
        }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule
    ],
    declarations: [AppComponent],
    providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: JwtInterceptor,
    //   multi: true
    // }, 
    AuthGuard, UserService, WasheriaModule],
    bootstrap: [AppComponent]
})
export class AppModule {}
