import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { WasheriaService } from './washeria.service';

@Component({
    selector: 'washeria-qrcodes',
    templateUrl: './washeria-qrcodes.component.html',
    styleUrls: ['./washeria-qrcodes.component.scss']
})
export class QRCodesComponent implements OnInit {

    constructor(private washeriaService: WasheriaService) {}
    
    ngOnInit() {

    }
}
