import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL, TOKEN_NAME } from "../../config";
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class UserService {

  private authenticated: boolean = false;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {}

  isAuthenticated():boolean {
    return !this.jwtHelper.isTokenExpired();
  }

  authenticate(email: string, password: string) {
    const auth$ = this.http.post(`${API_URL}/auth/login`, {
      email, password
    });

    auth$.subscribe(
      data => {
        if(data['token']) {
          const decodedToken = this.jwtHelper.decodeToken(data['token']);
          localStorage.setItem(TOKEN_NAME, data['token']);
        }
      }
    )

    return auth$;
  }

  deAuthenticate() {
    localStorage.removeItem(TOKEN_NAME);
    return true;
  }

  getToken() {
    const tokenStr = localStorage.getItem(TOKEN_NAME);
    const tokenData = JSON.parse(tokenStr);
    return tokenData;
  }
}