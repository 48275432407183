import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HttpClientModule } from '@angular/common/http';
import { DataTableModule } from "angular-6-datatable";


import { WasheriaRoutingModule } from './washeria-routing.module';
import { AccessesComponent } from './washeria-accesses.component';
import { QRCodesComponent } from './washeria-qrcodes.component';
import { WasheriaService } from './washeria.service';

@NgModule({
    imports: [CommonModule, 
      // HttpClientModule, 
      DataTableModule, 
      WasheriaRoutingModule],
    declarations: [AccessesComponent, QRCodesComponent],
    providers:[WasheriaService]
})
export class WasheriaModule {}
